// export const url = {
//     site: "",
//     websocket: "http://192.168.1.203:8070/",
// };

export async function fetcher<JSON = any>(
    input: RequestInfo,
    init?: RequestInit
): Promise<JSON> {
    const res = await fetch(input, init);
    return res.json();
}

export const url = {
    site : "https://plateforme-beta.reverto.fr",
    websocket : "https://plateforme-beta.reverto.fr"
}
