import * as MUIIcon from '@mui/icons-material';
import { SupportAgent } from '@mui/icons-material';
import { Grid } from '@mui/material';
import { AuthContext } from 'features/auth/store/auth.provider';
import { memo, useContext } from 'react';
import { Link } from 'react-router-dom';
import nav from './config/_nav';

import { createElement } from 'react';

import RNavItem from './RNavItem';
import RNavLink from './RNavLink';
import RNavTitle from './RNavTitle';

import logo from 'asset/logo/reverto_R_white-transparent.png';

import './style.css';

const Navbar = ({ open = false, setOpen, isMobile = false }) => {
    const { user } = useContext<any>(AuthContext);
    const logo_id = open ? "logo-open" : "logo-close";
    const support_button = open ? "support-button" : "support-button-close";

    return (
        <div id={"main-menu"}>
            {
                isMobile ? null :
                    open ?
                        <MUIIcon.ArrowBackIos className='retract-icon' onClick={() => setOpen(!open)} />
                        :
                        <MUIIcon.ArrowForwardIos className='retract-icon' onClick={() => setOpen(!open)} />
            }
            <Link to={"/"}>
                <img className="main-logo" id={logo_id} src={logo} alt="Reverto-logo" title="Logo-Reverto" />
            </Link>
            <Menu nav={nav} open={open} components={{ RNavItem, RNavTitle, RNavLink }} user={user} />
            {
                user.enterprise.name !== "REVERTO" ?
                    <Link className="RNavItemLink" to={"/contact"} >
                        <div className={support_button}>
                            <SupportAgent />
                            <span>Contacter Reverto</span>
                        </div>
                        <div className='version-site-web-container'>
                            v 0.9.61
                        </div>
                    </Link>
                    :
                    <div className="version-site-web-container">
                        v 0.9.61
                    </div>
            }

        </div>
    );
}

const Menu = ({ open, nav, components, user }) => {
    // console.log(user.enterprise.is_distributer);
    // console.log(nav);
    const createTabComponents = () => {
        var tabComponents = []
        for (var i = 0; i < nav.length; i++) {
            if (typeof components[nav[i]._tag] != "undefined") {
                var ComponentTemp = null;
                ComponentTemp = components[nav[i]._tag];
                if (typeof nav[i].icon == "string") {
                    nav[i].icon = MUIIcon[nav[i].icon.replace('Icon', '')];
                    nav[i].icon = createElement(nav[i].icon);
                }
                if (typeof nav[i].condition_appear !== "function") {
                    delete nav[i].condition_appear;
                    tabComponents.push(<ComponentTemp key={i} open={open} {...nav[i]} />);
                } else {
                    if (nav[i].condition_appear(user)) {
                        delete nav[i].condition_appear;
                        tabComponents.push(<ComponentTemp open={open} key={i} {...nav[i]} />);
                    }
                }
            }
        }
        return (tabComponents);
    }
    return (
        <>
            {createTabComponents()}
        </>
    )
}

export default memo(Navbar);


