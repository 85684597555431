import "./style.css";

const InputReverto = ({ label = "", handleChange, ...props }) => {
    const {
        id,
        disabled = false,
        type = "text",
        value,
        placeholder = "",
    } = props;
    return (
        <div className="container-input" {...props}>
            {label !== "" ? <span className="label-input">{label}</span> : null}
            <input
                id={id}
                className="textfield-reverto"
                type={type}
                disabled={disabled}
                value={value}
                placeholder={placeholder}
                autoComplete={type === "password" ? "new-password" : "off"}
                onChange={(event) => handleChange(event)}
            />
        </div>
    );
};

export default InputReverto;
