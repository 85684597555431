import React, { createContext, useEffect, useState } from "react";
import User from "../types/User";
import { useAuth } from "../hooks/useAuth";

type AuthContextType = {
  user: User;
  setUser : (user : User) => void;
};

export const AuthContext = createContext<AuthContextType>({
  user: {
    _id: "",
    email: "",
    firstname: "",
    lastname: "",
    permissions: [],
    role: null,
    enterprise: null,
  },
  setUser : () => {},
});

const init_user = {
  _id: "",
  email: "",
  firstname: "",
  lastname: "",
  permissions: [],
  role: null,
  enterprise: null,
}

const AuthProvider = ({ children }) => {
  const { auth } = useAuth();
  const [user, setUser] = useState<User>(init_user);

  useEffect(() => {
    setUser(auth.data as User);
  }, [auth]);

  const value: AuthContextType = {
    user: user,
    setUser : setUser
  };

  // console.log("auth context", value);

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

export default React.memo(AuthProvider);