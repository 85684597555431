import './style.css';

const TitleLayout = ({ title, subtitle = "", twoLines = false, ...props}) => {
    if(twoLines) {
        return (
            <div id="TitleLayoutTwoLine" {...props}>
                <p className='title'>{title}</p>
                <p className='subtitle'>{subtitle}</p>
            </div>
        )
    } else {
        return (
            <div id="TitleLayout" {...props}>
                <p className='title'>{title}</p>
                <p className='subtitle'>{subtitle}</p>
            </div>
        )
    }
}

export default TitleLayout;