import { useContext, useEffect, useState } from 'react';
import { useNavigate, useResolvedPath  } from 'react-router-dom';

import { AuthContext } from "features/auth/store/auth.provider";

import { Grid, Alert, AlertTitle } from '@mui/material';
import { useWindowSize } from 'features/misc/hooks/useWindowSize';
import TitleLayout from 'components/TitleLayout';
import { useAuth } from 'features/auth/hooks/useAuth';
import logo from 'asset/logo/reverto_R_white-transparent.png';

import './style.css';
import InputReverto from 'components/Inputs/InputReverto';
import ButtonReverto from 'components/Inputs/ButtonReverto';

const init_user = {
  email : '',
  password : '',
  error : ''
}

const Login = () =>  {
  const auth = useContext<any>(AuthContext);
  const url = useResolvedPath("").pathname;
  const navigate = useNavigate();
  const size = useWindowSize();
  const [userLogin, setUserLogin] = useState(init_user);
  const [isLogin, setIsLogin] = useState(true);

  const { login } = useAuth();

  const getUrlToConnect = () => {
    if(url === "/login" || url === "/") {
      return "/dashboard";
    };
    return url;
  }

  useEffect(() => {
    if(auth.user?._id !== "") {
      // console.log(auth.user);
      navigate(getUrlToConnect(), { replace : true });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);


  const handleInputChange = (event) => {
    setUserLogin({ ...userLogin, [event.target.id] : event.target.value });
  }

  const validateForm = (data) => {
      let isValidate = true;
      let error = "";
      if(data.email === "") {
          error ="L'email ne peut pas être vide \n";
          isValidate = false;
      }
      if(data.password === ""){
          error ="Le mot de passe ne peut pas être vide. \n";
          isValidate = false;
      }
      if(!isValidate) setUserLogin({...userLogin, 'error' : error });
      // console.log(data);

      return isValidate;
  }

  const onSubmit = (event) => {
    event.preventDefault();
    if(validateForm(userLogin)){
      login.mutate(userLogin);
    }
  }
  
  useEffect(() => {
    if(login.isError) {
      setUserLogin({...userLogin, 'error' : "Le login et/ou le mot de passe sont incorrects. \n" });
      // "Le login et/ou le mot de passe sont incorrects. \n";
      // "Erreur inattendue rencontrée. \n";
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [login.error]);

  return (
    <Grid container style={{ minHeight : size.height}} className="login-container">
      <div className='login-container-middle-logo' style={{ left : (size.width-200)/2+"px", top : (size.height-230)/2+"px"}}>
        <img src={logo} alt="logo" id="logo" />
      </div>
      <Grid item xs={12} md={6} className={ isLogin ? null : "login-inactive"}>
        <div className="login-form-container fade">
          <form className='login-container-form' onSubmit={onSubmit}>
            <TitleLayout title={"Bienvenue sur la plateforme de Reverto !"} />
            <InputReverto label="" id="email" type="text" value={userLogin.email} placeholder="Email" handleChange={handleInputChange} />
            <InputReverto label="" id="password" type="password" value={userLogin.password} placeholder="Mot de passe" handleChange={handleInputChange} />
            {/* <span className='link-password-forgot' >Mot de passe oublié ?</span> */}
            <ButtonReverto className="login-form-button" variant="contained" type="submit">Se connecter</ButtonReverto>
            {
              userLogin.error !== "" ?
                <Alert severity="error" className='OAlert'>{userLogin.error}</Alert>
              :
                ""
            }
            {/* <span className='link-change-login'  onClick={() => setIsLogin(false)}>S'inscrire</span> */}
          </form>
        </div>
      </Grid>
      <Grid item xs={0} md={6} className="background-login-container" style={{ width : size.width/2+"px" }} >
        <div className='login-container-login-filter'></div>
      </Grid>        
      <Grid item xs={12} md={6} className={ isLogin ? "subscribe-inactive" : null}>
        <div className="login-container-form fade" style={{ marginTop : (size.height - 500) /2+"px"}}>
          <TitleLayout title={"Bienvenue ! "} subtitle={"Sur notre outil d'administration"} />
          <form onSubmit={onSubmit}>
            <input className="login-form-input" type="text" placeholder="Nom entreprise" />
            <input className="login-form-input" type="text" placeholder="Email" />
            <input className="login-form-input" type="password" placeholder="Mot de passe"/>
            <input className="login-form-input" type="text" placeholder="Téléphone" />
            <span className='link-change-login' onClick={() => setIsLogin(true)}>Se connecter</span>
            {
              userLogin.error !== "" ?
                <Alert severity="error" className='OAlert'>
                  <AlertTitle className='OAlertTitle'>Une erreur est survenue</AlertTitle>
                  {userLogin.error}
                </Alert>
              :
                ""
            }
            {/* <Button className="login-form-button" variant="contained" type="submit">S'inscrire</Button> */}
          </form>
        </div>
      </Grid>
    </Grid>
  );
}

export default Login;