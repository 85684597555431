import { Grid } from '@mui/material';
import { Link } from 'react-router-dom';

import { AuthContext } from 'features/auth/store/auth.provider';
import { useContext } from 'react';
import './style.css';

const RNavLink = ({ icon, text, link, ...props }) => {
    const { user } = useContext(AuthContext);
    link = link.includes('enterprise') ? link + '/' + user.enterprise._id : link;
    console.log(link);
    return (
        <Link to={link} {...props}>
            <Grid item xs={12} id="RNavLink" >
                <i>{icon}</i>
                <p>{text}</p>
            </Grid>
        </Link>
    )
}

export default RNavLink;