import { useState, memo } from 'react';
import { Drawer, Fab } from '@mui/material';
import { Menu, Close} from '@mui/icons-material';
import NavBar from '../Navbar';

import './style.css';

const NavbarMobile = () => {
    const [open, setOpen] = useState(false);
    const toggleDrawer = (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setOpen( !open );
    };
    return (
        <>
            <Drawer className="drawerMenuMobile" anchor="right" open={open} onClose={toggleDrawer} >
                <div className="navbar-container">
                    <NavBar open={open} setOpen={setOpen} isMobile={true}/>
                </div>
            </Drawer>

            <Fab id="RButtonNavBar" aria-label="add" onClick={toggleDrawer}>
                {
                    open ? <Close /> : <Menu />
                }
            </Fab>
        </>
    );
}

export default memo(NavbarMobile);


