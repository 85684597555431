import { Avatar } from "@mui/material";
import LogoReverto from 'asset/logo/RevertoLogo.png';
import ActionsBar from "components/ActionsBar";
import { useState } from "react";
import { Link } from "react-router-dom";
import { stringAvatar } from "utils/AvatarUtil";

import ProfilMenu from "./ProfilMenu";
import "./style.css";

const UserBar = ({ user }) => {
    const [anchorProfil, setAnchorProfil] = useState<HTMLButtonElement | null>(
        null
    );

    const openProfil = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorProfil(event.currentTarget);
    };
    const closeProfil = () => {
        setAnchorProfil(null);
    };

    const open_profil = Boolean(anchorProfil);
    const id_profil = open_profil ? "popover-profil" : undefined;

    // console.log(user);

    return (
        <ActionsBar
            className="user-bar"
            leftContent={
                <Link to={"/"} className="user-bar-link">
                    <img
                        className="user-bar-logo"
                        title={user.enterprise.name}
                        src={user.enterprise.logo?.path || LogoReverto}
                        alt={user.enterprise.name}
                    />
                    <span>{user.enterprise.name}</span>
                </Link>
            }
            centerContent={""} // <SearchBar handleChangeSearch={() => {}} search={""} placeholder='Rechercher...' />
            rightContent={
                <RightActionUserBard
                    id_profil={id_profil}
                    user={user}
                    open_profil={open_profil}
                    openProfil={openProfil}
                    anchorProfil={anchorProfil}
                    closeProfil={closeProfil}
                />
            }
        />
    );
};

const RightActionUserBard = ({
    id_profil,
    user,
    open_profil,
    openProfil,
    anchorProfil,
    closeProfil,
}) => {
    return (
        <>
            <ProfilMenu
                anchorEl={anchorProfil}
                open={open_profil}
                onClose={closeProfil}
                handleCloseProfil={closeProfil}
            />
            <span className="user-profil-button" onClick={openProfil}>
                {!user.picture ? (
                    <Avatar
                        style={{ border: "2px solid #34AE9B" }}
                        {...stringAvatar(user.firstname + " " + user.lastname)}
                    />
                ) : null}
            </span>
        </>
    );
};

export default UserBar;
