import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Logout from "@mui/icons-material/Logout";

import { useAuth } from "features/auth/hooks/useAuth";
import { AuthContext } from "features/auth/store/auth.provider";
import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

function ProfilMenu({ anchorEl, open, onClose, handleCloseProfil }) {
    const { setUser } = useContext<any>(AuthContext);
    const navigate = useNavigate();
    const { logout } = useAuth();

    const logoutAction = () => {
        logout.mutate();
    };

    useEffect(() => {
        if (logout.isSuccess) {
            setUser(undefined);
            handleCloseProfil();
            navigate("/login", { replace: true });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [logout.isSuccess]);

    return (
        <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={onClose}
            onClick={onClose}
            PaperProps={{
                elevation: 0,
                sx: {
                    overflow: "hidden",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 0.5,
                    "& .MuiAvatar-root": {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                    },
                    borderRadius: "5px",
                    bgcolor: "var(--background-color)",
                    paddingTop: "16px",
                    paddingBottom: "16px",
                    borderBottom: "1px solid #34AE9B",
                },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
            <MenuItem
                onClick={logoutAction}
                sx={{
                    paddingRight: "24px",
                    paddingLeft: "24px",
                    color: "white",
                    fontFamily: "DDIN",
                    bgcolor: "var(--background-color)",
                   
                   ":focus": { bgcolor: "var(--background-color)" },
                   " :hover": { bgcolor: "var(--background-secondary-color)" },
                }}
            >
                <ListItemIcon>
                    <Logout fontSize="small" sx={{ color: "var(--text-secondary-color)" }} />
                </ListItemIcon>
                Déconnexion
            </MenuItem>
        </Menu>
    );
}

export default ProfilMenu;
