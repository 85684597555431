import './style.css';

const ActionsBar = ({ isSticky = false, leftContent = null, centerContent = null, rightContent = null, ...props }) => {
    return (
        <div className={ isSticky ? "actions-bar-container sticky" : "actions-bar-container"} {...props}>
            {
                leftContent && 
                <div className="actions-bar-left">
                    {leftContent}
                </div>
            }
            {
                centerContent &&
                <div className="actions-bar-center">
                    {centerContent}
                </div>
            }
            {
                rightContent && 
                <div className="actions-bar-right">
                    {rightContent}
                </div>
            }
        </div>
    )
}

export default ActionsBar;