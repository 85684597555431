import React, { createContext, useState } from "react";

export const InterfaceContext = createContext({});

const InterfaceProvider = ({ children }) => {
    const [interfaceView, setInterfaceView] = useState({
        sidebarShow: 'responsive',
        sidebarMinize: false,
        footerShow: true
    });

    return (
        <InterfaceContext.Provider value={{ interfaceView, setInterfaceView }}>
            {children }
        </InterfaceContext.Provider>
    );
}

export default InterfaceProvider;
