
import './style.css';

const ButtonReverto = ({ children, ...props }) => {
    return (
        <button className="button-reverto"  {...props}>
            {children}
        </button>
    );
}

export default ButtonReverto;