enum ROLES {
    ADMINISTRATION = "Administration",
    COMMERCIALISATION = "Commercialisation",
    PRODUCTION = "Production",
    RECHERCHE = "Recherche",
    SUPPORT = "Support",
    CLIENT_GERANT = "Client_Gerant",
}

const is_distributeur = (user) => {
    return user.enterprise.is_distributer;
};

const havePermission = (user, permissions: ROLES[]) => {
    return permissions.includes(user.role.name);
};

const all_users = [
    ROLES.ADMINISTRATION,
    ROLES.COMMERCIALISATION,
    ROLES.PRODUCTION,
    ROLES.RECHERCHE,
    ROLES.SUPPORT,
    ROLES.CLIENT_GERANT,
];

const nav = [
    {
        _tag: "RNavItem",
        text: "Tableau de bord",
        link: "/dashboard",
        icon: "ViewQuiltIcon",
        condition_appear: (user) => {
            return havePermission(user, all_users);
        },
    },
    {
        _tag: "RNavItem",
        text: "Production",
        link: "/contents",
        icon: "ArticleIcon",
        condition_appear: (user) => {
            return havePermission(user, [
                ROLES.ADMINISTRATION,
                ROLES.PRODUCTION,
            ]);
        },
    },
    {
        _tag: "RNavItem",
        text: "Mon entreprise",
        link: "/enterprise/utilisateurs",
        icon: "SupervisedUserCircle",
        condition_appear: (user) => {
            return havePermission(user, [
                ROLES.ADMINISTRATION,
                ROLES.COMMERCIALISATION,
                ROLES.CLIENT_GERANT,
            ]);
        },
    },
    {
        _tag: "RNavItem",
        text: "Mes licences",
        link: "/enterprise/licences",
        icon: "RequestPage",
        condition_appear: (user) => {
            return havePermission(user, [
                ROLES.ADMINISTRATION,
                ROLES.COMMERCIALISATION,
                ROLES.CLIENT_GERANT,
            ]);
        },
    },
    {
        _tag: "RNavItem",
        text: "Mon matériel",
        link: "/enterprise/materiels",
        icon: "DevicesIcon",
        condition_appear: (user) => {
            return havePermission(user, [
                ROLES.ADMINISTRATION,
                ROLES.COMMERCIALISATION,
                ROLES.CLIENT_GERANT,
            ]);
        },
    },
    {
        _tag: "RNavItem",
        text: "Mes clients",
        link: "/enterprise/entreprises",
        icon: "Work",
        condition_appear: (user) => {
            return (
                is_distributeur(user) &&
                havePermission(user, [
                    ROLES.ADMINISTRATION,
                    ROLES.COMMERCIALISATION,
                ])
            );
        },
    },
    // {
    //     _tag: 'RNavItem',
    //     text: 'Mes matériels',
    //     link: '/application/materiels',
    //     icon: 'DevicesIcon',
    //     condition_appear : (user) => {
    //         return havePermission(user, [ROLES.ADMINISTRATION, ROLES.COMMERCIALISATION, ROLES.CLIENT_GERANT]);
    //     }
    // },
    // {
    //     _tag: 'RNavItem',
    //     text: 'Licences',
    //     link: '/licences',
    //     icon: 'RequestPageIcon'
    // },
    // {
    //     _tag: 'RNavItem',
    //     text: 'Missions',
    //     link: '/missions',
    //     icon: 'DynamicFormIcon',
    //     condition_appear : (user) => {
    //         return havePermission(user, [ROLES.ADMINISTRATION]);
    //     }
    // },
    // {
    //     _tag: 'RNavItem',
    //     text: 'Tokens/Datas',
    //     link: '/tokens',
    //     icon: 'SupportIcon'
    // },
    // {
    //     _tag: 'RNavItem',
    //     text: 'Rapports datas',
    //     link: '/reports',
    //     icon: 'PlagiarismIcon'
    // },
    // {
    //     _tag: 'RNavItem',
    //     text: 'Ressources',
    //     link: '/ressources',
    //     icon: 'FilePresentIcon'
    // }
    {
        _tag: "RNavItem",
        text: "Tokens",
        link: "/tokens",
        icon: "TokenIcon",
        condition_appear: (user) => {
            // console.log(user);
            return (
                is_distributeur(user) &&
                havePermission(user, [ROLES.ADMINISTRATION])
            );
        },
    },
    {
        _tag: "RNavItem",
        text: "Paramètres",
        link: "/parameters",
        icon: "BuildIcon",
        condition_appear: (user) => {
            // console.log(user);
            return (
                is_distributeur(user) &&
                havePermission(user, [ROLES.ADMINISTRATION])
            );
        },
    },
];

export default nav;
